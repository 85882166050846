// extracted by mini-css-extract-plugin
export var cardBody = "character-grid-icon-module--card-body--aGf7v";
export var characterArt = "character-grid-icon-module--character-art--bpUmS";
export var characterBorderSvg = "character-grid-icon-module--character-border-svg--UWjIg";
export var characterClip = "character-grid-icon-module--character-clip--vXo1h";
export var characterContainer = "character-grid-icon-module--character-container--TCSty";
export var characterModal = "character-grid-icon-module--character-modal--REKeT";
export var characterModalOverlay = "character-grid-icon-module--character-modal-overlay--X75dQ";
export var characterName = "character-grid-icon-module--character-name--C9Qly";
export var characterNameBox = "character-grid-icon-module--character-name-box--hV3XV";
export var characterNameOverlay = "character-grid-icon-module--character-name-overlay--OYgJu";
export var characterOverlayGrid = "character-grid-icon-module--character-overlay-grid--uDsm3";
export var closeButton = "character-grid-icon-module--close-button--3orgl";
export var closeButtonImage = "character-grid-icon-module--close-button-image--KA-HQ";
export var descriptionBox = "character-grid-icon-module--description-box--9JRWK";
export var descriptionSection = "character-grid-icon-module--description-section--EC5ao";
export var imageSection = "character-grid-icon-module--image-section--i06oH";
export var statsText = "character-grid-icon-module--stats-text--wrOFs";
export var topBar = "character-grid-icon-module--top-bar--PvDZV";
export var triangleContainer = "character-grid-icon-module--triangle-container--LW755";
export var triangleSvg = "character-grid-icon-module--triangle-svg--6oP4X";