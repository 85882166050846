// extracted by mini-css-extract-plugin
export var aboutSection = "index-module--about-section--S4eA3";
export var bigGap = "index-module--big-gap--O1-3N";
export var cartoonTitle = "index-module--cartoon-title--F3X7D";
export var discordFrame = "index-module--discord-frame--X0U3q";
export var emptyBox = "index-module--empty-box--ytj2-";
export var greenBox = "index-module--green-box--NzThj";
export var mythrenGrid = "index-module--mythren-grid--TUonr";
export var mythrenGridContainer = "index-module--mythren-grid-container--lueio";
export var mythrenGridPaddingBox = "index-module--mythren-grid-padding-box--jWYAE";
export var newsGrid = "index-module--news-grid--MJ4wj";
export var pressBox = "index-module--press-box--QF4zl";
export var pressButton = "index-module--press-button--E1vNA";
export var pressButtonContainer = "index-module--press-button-container--yWdQF";
export var smallHeader = "index-module--small-header--WMYs4";
export var socialBox = "index-module--social-box--9rxE0";
export var socialSidebar = "index-module--social-sidebar--Io3Vj";
export var twitterBox = "index-module--twitter-box--+OvqB";